// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
}
// REMIX HMR END

import { Form, json, redirect, useActionData, useNavigation } from "@remix-run/react";
export const meta = () => {
  return [{
    title: "屋外ルーターの状態"
  }];
};
export const headers = () => {
  return {
    "Cache-Control": "max-age=0, s-maxage=30, stale-while-revalidate=30"
  };
};
import styles from "~/styles/index.css";
export const links = () => [{
  rel: "stylesheet",
  href: styles
}];
export const action = async ({
  request
}) => {
  const formdata = await request.formData();
  const res = await fetch(`https://${process.env.API_HOST}/login`, {
    headers: {
      "Content-Type": "application/json"
    },
    method: "post",
    body: JSON.stringify({
      name: formdata.get("name"),
      password: formdata.get("password")
    })
  });
  if (res.status === 200) {
    return redirect(`/router/${formdata.get("name")}`);
  }
  return json({
    status: res.status
  });
};
export default function Index() {
  _s();
  const data = useActionData();
  const navigation = useNavigation();
  return <div style={{
    fontFamily: "system-ui, sans-serif",
    lineHeight: "1.8"
  }}>
      <h1 className="center">ルーター情報</h1>
      <Form className="center" method="post">
        <div>
          <label>
            <span>ルーター/カメラ名</span>
            <input type="text" name="name" />
          </label>
        </div>

        <div>
          <label>
            <span>パスワード</span>
            <input type="password" name="password" />
          </label>
        </div>
        <button type="submit">ログイン</button>
        <div>{navigation.state === "submitting" ? "ログイン中..." : ""}</div>
        <div>{data ? "カメラ名、またはパスワードが間違っています" : ""}</div>
      </Form>
    </div>;
}
_s(Index, "VraVGAL90gV7Flw0Yv7Vhlrbn+U=", false, function () {
  return [useActionData, useNavigation];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;